<script>
	import { useMeta } from "vue-meta";
	import Menu from "@/components/Heineken-sfast_Q4/Menu";
	import Alert from "@/components/Alert";

	export default {
		name: "Heineken-sfastQ4_home",
		data() {
			return {
				isActiveAlert: false,
			};
		},
		setup() {
			useMeta({
				title: "海尼根啤酒 登錄發票抽SuperFast海尼根配色電單車",
				description: "2024/6/1 -7/31 至指定通路，購買海尼根全系列啤酒任一組，登錄發票就抽SuperFast海尼根配色電單車!!!",
			});
		},
		components: {
			Menu,
			Alert,
		},
		computed: {},
		watch: {},
		mounted() {},
		methods: {
			checkBrowser() {
				// console.log("checkBrowser")
				let vm = this;
				var useragent = navigator.userAgent;
				var rules = ["WebView", "(iPhone|iPod|iPad)(?!.*Safari\/)", "Android.*(Instagram|Line|FBAN|FBAV)"];
				var regex = new RegExp(`(${rules.join("|")})`, "ig");
				if (Boolean(useragent.match(regex))) {
					vm.isActiveAlert = true;
				} else {
					location.href = "https://heineken-event.webgene.com.tw/auth/redirect/heineken-superfast";
				}
			},
		},
	};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
